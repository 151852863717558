<template>
    <div>
        <div id="heade-action" class="w-100 bg-white sticky-top p-3" style="align-items:center;justify-content:center;">
            <div>
                <img :src="$api.ste.logo" class="logo"/>
            </div>
            <div class="ml-auto">                
            </div>
            <div class="ml-auto" v-if="contact.user_telephone != undefined">                
                <b-button class="ml-2" size="sm" :variant="call_color" :href="`tel:${contact.user_telephone}`">
                    <i class="fas fa-phone"></i><br/>
                    <span class="h6">Appelez nous</span>
                </b-button>
            </div>
        </div>
        <b-container v-if="ready"  class="mt-3" style="margin-bottom:50px">            
            <b-row class="justify-content-center">
                <b-col cols="12">
                    <div class="p-2 text-center mt-5 mb-5">
                        <span class="text-white h2"></span>
                    </div>
                </b-col>
                <b-col lg="9" md="11">  
                    <b-card class="card-custom" v-if="!send">
                        <div class="text-center mb-5">
                            <p class="h5">
                                {{ contact.titre }}<br/>                                
                            </p>
                        </div>
                        <form ref="form">
                            <b-row class="mt-5 justify-content-center">
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.nom" placeholder="Nom" required/>                                
                            </b-col>
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.prenom" placeholder="Prénom" required/>                                                                
                            </b-col>
                            <b-col md="4" class="mb-4" v-if="form_complet">
                                <b-form-input type="text" v-model="contact.email" placeholder="Email"/>                                                                                                
                            </b-col>
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.telephone_1" placeholder="Téléphone" minlength="10" maxlength="10" required/>                                                                
                            </b-col>
                            <b-col md="4" class="mb-4" v-if="form_complet">
                                <b-form-input type="text" v-model="contact.cp" placeholder="Code postal"/>                                                                                                                                
                            </b-col>                            
                            <b-col md="4" class="mb-4" v-if="form_complet">
                                <!-- <b-form-datepicker class="custom-date-picker" placeholder="Date de naissance" v-model="contact.date_naissance"></b-form-datepicker> -->
                                <b-form-input placeholder="Date de naissance" v-model="contact.date_naissance" @input="e => formatDate(e, 'p')" maxlength="10" required/>
                            </b-col>                            
                            <b-col md="4" class="mb-4" v-if="form_complet">
                                <b-form-checkbox size="lg" v-model="contact.conjoint.regime_id" :value="1" :unchecked-value="null">Avez-vous un conjoint ?</b-form-checkbox>
                            </b-col>                            
                            <b-col md="4" class="mb-4" v-if="form_complet && contact.conjoint.regime_id === 1">
                                <!-- <b-form-datepicker class="custom-date-picker" placeholder="Date de naissance du conjoint" v-model="contact.conjoint.date_naissance"></b-form-datepicker> -->                                
                                <b-form-input placeholder="Date de naissance du conjoint" v-model="contact.conjoint.date_naissance" @input="e => formatDate(e, 'c')" maxlength="10" required/>
                            </b-col>                            
                            <b-col md="12 mt-5">
                                <p class="h6 text-primary">{{ contact.sous_titre }}</p>
                            </b-col>
                        </b-row>                        
                        </form>
                        <div class="text-center mt-5">
                            <b-button variant="primary" @click="save">
                                <span>Envoyez</span>
                                <i v-if="contactLoad" class="fas fa-spin fa-spinner"></i>
                            </b-button>
                        </div>
                    </b-card>
                    <b-card class="card-custom" v-else>
                        <div class="">
                            <p class="h5 mb-5">Merci, votre demande a bien été enregistré !</p>
                            <div class="text-center" v-if="contact.user_telephone != undefined">                                
                                <b-button class="ml-2" :variant="call_color" :href="`tel:${contact.user_telephone}`">                                    
                                    <span class="h6">Appelez nous</span>
                                </b-button>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>            
        </b-container>                        
    </div>
</template>
<script>        
    export default {
        name: "Campagne",
        //components: {Input},
        computed: {},
        data() {
            return {
                ready: false,                                
                call_color: 'primary',
                send:false,  
                contactLoad:false, 
                form_complet: false,
                contact:{      
                    titre:"Formule de contact",     
                    sous_titre:"",  
                    regime_id:1,       
                    nom:null,
                    prenom:null,
                    email:null,
                    telephone_1:null,
                    code:null,
                    conjoint:{
                        regime_id: null,
                        date_naissance: null
                    }, 
                    commentaire: null
                }
            }
        },
        watch:{
            'contact.date_naissance'(v){
                var v2 = this.contact.conjoint.date_naissance;
                var txt = "";
                if(v != null){
                    txt += "Date de naissance: "+v; 
                }
                if(v2 != null){
                    txt += "\n";
                    txt += "Date de naissance du conjoint: "+ v2;
                }
                this.contact.commentaire = txt;                                
            },
            'contact.conjoint.date_naissance'(v2){
                var v = this.contact.date_naissance;
                var txt = "";
                if(v != null){
                    txt += "Date de naissance: "+v; 
                }
                if(v2 != null){
                    txt += "\n";
                    txt += "Date de naissance du conjoint: "+ v2;
                }
                this.contact.commentaire = txt;                                
            }
        },
        beforeMount(){            
            if(this.$route.params.q != undefined){
                var base64 = this.$route.params.q;                
                const binaryString = atob(base64);
                const bytes = Uint8Array.from(binaryString, char => char.charCodeAt(0));
                const decodedString = new TextDecoder('utf-8').decode(bytes);
                var params = JSON.parse(decodedString);                
                if(params.form_complet != undefined){
                    this.form_complet = params.form_complet;
                    delete params.form_complet;
                }
                this.contact = {... this.contact, ...params};                
            }
        },
        mounted() {              
            setInterval(() => {
                this.call_color = this.call_color === 'primary' ? 'light-danger' : 'primary';
            },2000)                              
            this.ready = true;           
        },        
        methods: {                        
            save(){                
                if(this.contactLoad === true){
                    return true;
                }
                if(!this.checkForm()){
                    return false;
                }
                this.contactLoad = true;
                this.$api.ajax('/campagne/new', this.contact, res => {
                    if(res.status === true){
                        this.send = true;
                    }
                    this.contactLoad = false;
                })
            },
            checkForm(){
                var form = this.$refs.form;
                var fields = form.querySelectorAll("[required='required']");
                var valide = true;
                fields.forEach((item) => {
                    if(item.reportValidity() === false){
                        item.classList.add("need-validation");
                        valide = false;
                    }else{          
                        item.classList.remove("need-validation");
                    };
                });
                if(valide === false){
                    this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
                        title: 'Champs',
                        variant: 'warning',
                        solid: true
                    });
                    this.tabIndex = 0;
                    return false;
                }
                return true;
            },
            reload(){
                location.reload()
            },
            formatDate(event, type) {                               
                let input = event.replace(/\D/g, ''); // Supprime tout ce qui n'est pas un chiffre       
                if (input.length > 8) {
                    input = input.slice(0, 8);
                }      
                if (input.length > 2 && input.length <= 4) {
                    input = input.slice(0, 2) + '/' + input.slice(2);
                } else if (input.length > 4) {
                    input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4);
                }
                if(type === 'p'){
                    this.contact.date_naissance = input;
                }else if(type === 'c'){
                    this.contact.conjoint.date_naissance = input;
                }
            },
        },        
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    .need-validation {
        border-bottom:none !important;
        border: solid 2px red !important;
        border-radius: 10px !important
    }
    .form-control {
        border:none;
        border-radius:0px;
        border-bottom: #416eaf solid 2px;
    }
    .form-control::placeholder {
        color:rgb(59, 59, 59);
        font-size: 14px;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    } 
    .custom-date-picker .form-control.text-muted{
        color:rgb(59, 59, 59) !important;
        font-size: 14px !important;
    }           
</style>